/* eslint-disable react-hooks/exhaustive-deps */

import { useDebounce } from 'hooks/useDebounce';
import React, { FC, useCallback, useLayoutEffect, useRef, useState } from 'react';

import { IUsersRequest } from 'services/api/users/types';
import IconSvg from 'ui/Svg/IconSvg';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { AnimatePresence, motion } from 'framer-motion/dist/framer-motion';
import { ISearchSelectItem, IUsersSearchProps } from '../types';
import { EPermissionNames } from '../../../../redux/reducers/auth/types';
import { resetActiveBankAccounts } from '../../../../redux/reducers/bankAccounts/reducer';
import { resetFilters } from '../../../../redux/reducers/transactionsFilters/reducer';
import { EFiltersType } from '../../../../redux/reducers/transactionsFilters/types';
import { getPermissions } from '../../../../redux/reducers/auth/selectors';
import { ROUTES } from '../../../../routes/constants';

const arr = [
	{ name: 'User ID', value: 'user_id' },
	{ name: 'Email', value: 'email' },
	{ name: 'Company Name', value: 'company_name' },
	{ name: 'Full Name', value: 'full_name' },
];

const UsersSearch: FC<IUsersSearchProps> = ({
	total,
	setQuery,
	tab,
	leftColum = true,
	children,
	selectFieldChildren,
	resetSelect,
	isResetable,
}) => {
	const permissions = useSelector(getPermissions);
	const history = useHistory();
	const dispatch = useDispatch();

	const [searchField, setSearchField] = useState<ISearchSelectItem>({
		name: 'User ID',
		value: 'user_id',
	});
	const [searchValue, setSearchValue] = useState('');
	const [opened, setOpened] = useState(false);
	const debouncedSearch = useDebounce(searchValue, 500);
	const selectBlockRef = useRef<HTMLDivElement>(null);
	const [searchArr, setSearchArr] = useState<
		| {
				name: string;
				value: string;
		  }[]
		| []
	>([]);

	const arrReset = [
		{
			name: tab === 'user' ? 'User ID' : 'Admin ID',
			value: tab === 'user' ? 'user_id' : 'admin_id',
		},
		{
			name: 'Email',
			value: 'email',
		},
		{
			name: 'Full Name',
			value: 'full_name',
		},
	];
	const arrResetManag = [
		{
			name: tab === 'user' ? 'User ID' : 'Admin ID',
			value: 'user_id',
		},
		{
			name: 'Email',
			value: 'email',
		},
		{
			name: 'Company Name',
			value: 'company_name',
		},
		{
			name: 'Full Name',
			value: 'full_name',
		},
	];
	useLayoutEffect(() => {
		if (tab) {
			setSearchArr(arrReset);
			if (tab === 'admin') {
				setSearchValue('');
				setSearchField({
					name: 'Admin ID',
					value: 'user_id',
				});
			} else if (tab === 'user') {
				setSearchValue('');
				setSearchField({
					name: 'User ID',
					value: 'user_id',
				});
				setSearchArr(arrResetManag);
			}
			if (tab === 'admin_tab') {
				setSearchField({
					name: 'Admin ID',
					value: 'admin_id',
				});
			}

			// else {
			// 	setSearchField({
			// 		name: 'User ID',
			// 		value: 'user_id',
			// 	});
			// }
		} else setSearchArr(arr);
	}, [tab]);

	const handleSelect = useCallback((el: ISearchSelectItem) => {
		setSearchField(el);
		setSearchValue('');
		setOpened(false);
	}, []);

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setSearchValue(e.target.value);
	};

	const toggleSelect = () => {
		setOpened(!opened);
	};

	useLayoutEffect(() => {
		const handleOutsideClick = (event: MouseEvent) => {
			if (!selectBlockRef.current?.contains(event.target as Node)) {
				setOpened(false);
			}
		};

		if (opened) {
			document.addEventListener('mousedown', handleOutsideClick);
		}
		return () => document.removeEventListener('mousedown', handleOutsideClick);
	}, [opened]);

	useLayoutEffect(() => {
		if (debouncedSearch && searchValue) {
			if (tab === 'admin' || tab === 'admin_tab' || tab === 'user') {
				setQuery((prevState: IUsersRequest) => ({
					...prevState,
					[searchField.value]: debouncedSearch,
					current_page: 1,
				}));
			}
			setQuery((prevState: IUsersRequest) => ({
				...prevState,
				search_field: searchField.value,
				search_value: debouncedSearch,
				// [searchField.value]: debouncedSearch,
				current_page: 1,
			}));
			return;
		}
		setQuery((prevState) => ({ per_page: prevState.per_page, current_page: 1 }));
	}, [debouncedSearch, searchField.value, setQuery]);

	const handleCreateUser = () => {
		history.push('/createUser/userInfo');
		dispatch(resetActiveBankAccounts());
		dispatch(resetFilters(EFiltersType.BANK_ACCOUNTS_WITH_SELECT));
	};
	const handleCreateAdmin = () => {
		history.push(ROUTES.adminManagement.CREATE_ADMIN);
	};
	const tubButtonCheck = () => {
		if (tab === 'user') {
			return (
				<>
					{!!permissions?.[EPermissionNames.USER_MANAGEMENT].editable && (
						<div className="title-block-button">
							<div className="title-block-button button--flex-end">
								<button onClick={handleCreateUser} type="button" className="btn btn-primary">
									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="21"
										height="20"
										viewBox="0 0 21 20"
										fill="none"
									>
										<path
											d="M9.87435 15.8307V10.6224H4.66602V9.3724H9.87435V4.16406H11.1243V9.3724H16.3327V10.6224H11.1243V15.8307H9.87435Z"
											fill="white"
										/>
									</svg>
									Create account
								</button>
							</div>
						</div>
					)}
				</>
			);
		}
		if (tab === 'admin_tab') {
			return (
				<>
					{!!permissions?.[EPermissionNames.ADMIN_MANAGEMENT].editable && (
						<div className="title-block-button">
							<div className="title-block-button button--flex-end">
								<button onClick={handleCreateAdmin} type="button" className="btn btn-primary">
									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="21"
										height="20"
										viewBox="0 0 21 20"
										fill="none"
									>
										<path
											d="M9.87435 15.8307V10.6224H4.66602V9.3724H9.87435V4.16406H11.1243V9.3724H16.3327V10.6224H11.1243V15.8307H9.87435Z"
											fill="white"
										/>
									</svg>
									Add New Admin
								</button>
							</div>
						</div>
					)}
				</>
			);
		}
		return null;
	};
	const resetHandler = () => {
		setSearchValue('');
		setSearchArr(arr);
		setSearchField({
			name: 'User ID',
			value: 'user_id',
		});
		if (resetSelect) resetSelect();
	};

	return (
		<div
			className={isResetable ? 'table-filter flex-m justify-content-start' : 'table-filter flex-m'}
		>
			{/* <p>{null}</p> */}
			{/* {leftColum === true && tab !== 'admin_tab' ? (
				<p className="count-label">
					{total} {tab === 'user' ? 'Users' : 'Admines'}
				</p>
			) : null}
			{tab === 'admin_tab' ? (
				<p className="count-label count-label--centered">All users ({total})</p>
			) : null} */}
			{selectFieldChildren && children}
			<div className="filter">
				<div className="search-input-wrap">
					<div className="search-input">
						<div ref={selectBlockRef} className={`select select--type4 ${opened ? 'active' : ''}`}>
							<button type="button" className="select__current" onClick={toggleSelect}>
								{searchField?.name}
								<span className="select__current-arrow">
									<IconSvg name="keyboard-arrow-down" w="9" h="5" />
								</span>
							</button>
							<AnimatePresence>
								{opened && (
									<motion.div
										className="select__drop"
										initial={{ opacity: 0 }}
										animate={{ opacity: 1, transition: { duration: 0.3 } }}
										exit={{ opacity: 0, transition: { duration: 0.3 } }}
									>
										<div className="select__drop-scroll">
											<div className="select__drop-item">
												<ul>
													{searchArr.map((el) => (
														<li key={el.value}>
															<button type="button" onClick={() => handleSelect(el)}>
																{el.name}
															</button>
														</li>
													))}
												</ul>
											</div>
										</div>
									</motion.div>
								)}
							</AnimatePresence>
						</div>

						<div className="input input--mb-0">
							<div className="input-wrapper">
								<input
									className="input-item input-item--type2 input-item--pr50"
									type="text"
									placeholder={`Search by ${searchField.name}`}
									value={searchValue}
									onChange={handleChange}
								/>
								<div className="input-icon input-icon--auto input-icon--right">
									<IconSvg name="search" w="24" h="24" />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			{isResetable && (
				<div className="filter--right">
					<button type="button" className="btn btn-reset" onClick={resetHandler}>
						Reset
					</button>
				</div>
			)}
			{tubButtonCheck()}
		</div>
	);
};

export default UsersSearch;
