import React, { FC, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import { roundingNumber } from 'services/utils/roundingNumber';
import { deleteUserReferralRequest } from 'redux/reducers/users/reducer';
import WarningPopUp from 'layouts-elements/PopUp/WarningPopUp/WarningPopUp';

import IconSvg from 'ui/Svg/IconSvg';
import { IReferralTableRowProps } from '../types';

const ReferralTableRow: FC<IReferralTableRowProps> = ({
	id,
	data,
	email,
	bonusAccrued,
	tradeVolume,
	commission,
	userId,
	permission,
	referralSystem,
	setCurrentPage,
	setItemsPerPage,
}) => {
	const dispatch = useDispatch();

	const [openConfirmModal, setOpenConfirmModal] = useState(false);

	const handleOpenConfirmModal = () => {
		setOpenConfirmModal(true);
	};

	const closeConfirmModal = () => {
		setOpenConfirmModal(false);
	};

	const handleConfirm = useCallback(() => {
		dispatch(deleteUserReferralRequest({ userId, referral_id: id }));
		setItemsPerPage(10, true);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, id, userId, setCurrentPage]);

	return (
		<div className="tr">
			<div className="td">
				<p className="td-hidden-name">User ID</p>
				<div className="td__wrap">
					<Link to={`/user-management/${id}/account`} className="link">
						{id}
					</Link>
				</div>
			</div>
			<div className="td">
				<p className="td-hidden-name">Referred User</p>
				<div className="td__wrap">
					<p>
						{data?.first_name} {data?.last_name}
					</p>
				</div>
			</div>
			<div className="td">
				<p className="td-hidden-name">Email</p>
				<div className="td__wrap">
					<Link to={`/user-management/${id}/account`} className="link link--type2">
						{email}
					</Link>
				</div>
			</div>
			<div className="td">
				<p className="td-hidden-name">Total Commission</p>
				<div className="td__wrap">
					{/* <p>{roundingNumber(bonusAccrued)} EUR</p> */}
					<p>{roundingNumber(data?.commission)} EUR</p>
				</div>
			</div>
			<div className="td">
				<p className="td-hidden-name">Trade Value</p>
				<div className="td__wrap">
					<p>{roundingNumber(tradeVolume?.total)} EUR</p>
				</div>
			</div>
			{/* <div className="td"> */}
			{/*	<p className="td-hidden-name">Commission</p> */}
			{/*	<p>{roundingNumber(data?.commission)} EUR</p> */}
			{/* </div> */}
			<div className="td">
				<p className="td-hidden-name">Percentage %</p>
				<div className="td__wrap">
					<p>{referralSystem?.commission}</p>
				</div>
			</div>
			<div className="td td--right">
				<p className="td-hidden-name">Action</p>
				<div className="table-buttons flex-e">
					{permission && (
						// eslint-disable-next-line jsx-a11y/control-has-associated-label
						<button
							type="button"
							className="btn btn--icon-danger btn--delete"
							onClick={handleOpenConfirmModal}
						/>
					)}
				</div>
			</div>
			<WarningPopUp
				open={openConfirmModal}
				closeModal={closeConfirmModal}
				title="Delete Referral"
				message="Are you sure you want to delete the referral?"
				confirmHandler={handleConfirm}
			/>
		</div>
	);
};

export default ReferralTableRow;
