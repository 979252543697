import { call, put, select, takeEvery } from 'redux-saga/effects';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import { PayloadAction } from '@reduxjs/toolkit';
import { api } from 'services';
import { notificationContainer } from 'services/utils/notificationContainer';
import { popUpOpen, setPopUpData } from 'redux/reducers/popUp/reducer';
import {
	getWalletAddressesRequest,
	updateWalletAddressRequest,
	deleteWalletAddressRequest,
	getWalletAddressesSuccess,
	changeWalletAddressesPage,
} from './reducer';
import {
	IWalletAddressesPageInfo,
	IWalletAddressGetResponse,
	IWalletAddressRequestPayload,
} from './types';
import { getWalletAddressesPageInfo, getWalletAddressesUserId } from './selectors';
import { notificationsMessagesPopUp } from '../../../services/utils/notificationsMessages/notificationsMessagesPopUp';
// =============================================================:

// =============================================================:
function* getWalletAddressesRequestWorker() {
	try {
		yield put(showLoading());
		const userId: number = yield select(getWalletAddressesUserId);
		const pageInfo: IWalletAddressesPageInfo = yield select(getWalletAddressesPageInfo);
		const response: IWalletAddressGetResponse = yield call(api.walletAddresses.getWalletAddresses, {
			userId,
			pageInfo,
		});

		yield put(getWalletAddressesSuccess(response));
	} catch (error) {
		notificationContainer('Wallet address were not found.', 'error');
	} finally {
		yield put(hideLoading());
	}
}
// =============================================================:
function* deleteWalletAddressesRequestWorker(action: PayloadAction<{ id: number }>) {
	const { payload } = action;
	try {
		yield put(showLoading());
		const userId: number = yield select(getWalletAddressesUserId);
		yield call(api.walletAddresses.deleteWalletAddress, { userId, address_id: payload.id });
		yield put(getWalletAddressesRequest());
		yield put(popUpOpen('successPopUp'));
	} catch (error) {
		yield put(setPopUpData({ message: notificationsMessagesPopUp.walletDeleted }));
		yield put(popUpOpen('errorPopUp'));
	} finally {
		yield put(hideLoading());
	}
}
// =============================================================:
function* updateWalletAddressesRequestWorker(action: PayloadAction<IWalletAddressRequestPayload>) {
	const { payload } = action;
	try {
		yield put(showLoading());
		const userId: number = yield select(getWalletAddressesUserId);
		yield call(api.walletAddresses.updateWalletAddress, { userId, ...payload });
		yield put(getWalletAddressesRequest());
		yield put(
			setPopUpData({
				title: 'Wallet Address updated',
				message: notificationsMessagesPopUp.walletSuccessfully(payload?.data?.nickname),
			}),
		);
		yield put(popUpOpen('successPopUp'));
	} catch (error) {
		yield put(setPopUpData({ message: notificationsMessagesPopUp.walletUpdated }));
		yield put(popUpOpen('errorPopUp'));
	} finally {
		yield put(hideLoading());
	}
}

// =============================================================:
export function* walletAddressesSaga() {
	yield takeEvery(getWalletAddressesRequest.type, getWalletAddressesRequestWorker);
	yield takeEvery(updateWalletAddressRequest.type, updateWalletAddressesRequestWorker);
	yield takeEvery(deleteWalletAddressRequest.type, deleteWalletAddressesRequestWorker);
	yield takeEvery(changeWalletAddressesPage.type, getWalletAddressesRequestWorker);
}
