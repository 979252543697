import React, { FC, useLayoutEffect, useMemo, useState } from 'react';
import ReactPaginate from 'react-paginate';
import { IUserWalletsItem } from 'services/api/users/types';
import IconSvg from 'ui/Svg/IconSvg';
import { ECoinType, IWalletsTable } from './types';
import WalletTableHeader from './WalletsTableHeader';
import WalletTableRow from './WalletTableRow';

const WalletsTable: FC<IWalletsTable> = ({ title, walletsList, perPage, type, permission }) => {
	const [sortOrder, setSortOrder] = useState<string>('');
	const [sortItem, setSortItem] = useState<string>('');

	const sortedWalletsList = useMemo(() => {
		if (!walletsList) return [];

		let sort = sortItem;
		if (sortItem === 'total' && walletsList[0].asset.type === 'crypto') {
			sort = 'balance_eur';
		}
		if (sortItem === 'total' && walletsList[0].asset.type === 'token') {
			sort = 'balance';
		}
		if (sortItem === 'total' && walletsList[0].asset.type === 'fiat') {
			sort = 'balance_eur';
		}
		if (sortItem === 'fiat' && walletsList[0].asset.type === 'crypto') {
			sort = 'balance_eur';
		}

		if (sortOrder === 'desc') {
			return [...walletsList].sort((a: any, b: any) => {
				if (sortItem === 'fiat' && a.asset.chains && b.asset.chains) {
					const firstItem = a.asset.chains[0].balances[sort as keyof typeof a.asset.chains];
					const secondItem = b.asset.chains[0].balances[sort as keyof typeof b.asset.chains];
					return firstItem > secondItem ? -1 : 1;
				}
				const firstItem = a.asset[sort as keyof typeof a.asset] || a[sort as keyof typeof a];
				const secondItem = b.asset[sort as keyof typeof b.asset] || b[sort as keyof typeof b];
				return firstItem > secondItem ? -1 : 1;
			});
		}

		if (sortOrder === 'asc') {
			return [...walletsList].sort((a: any, b: any) => {
				if (sortItem === 'fiat' && a.asset.chains && b.asset.chains) {
					const firstItem = a.asset.chains[0].balances[sort as keyof typeof a.asset.chains];
					const secondItem = b.asset.chains[0].balances[sort as keyof typeof b.asset.chains];
					return firstItem > secondItem ? -1 : 1;
				}
				const firstItem = a.asset[sort as keyof typeof a.asset] || a[sort as keyof typeof a];
				const secondItem = b.asset[sort as keyof typeof b.asset] || b[sort as keyof typeof b];
				return firstItem < secondItem ? -1 : 1;
			});
		}
		return walletsList;
	}, [sortItem, sortOrder, walletsList]);

	const [currentItems, setCurrentItems] = useState<IUserWalletsItem[] | null>(null);
	const [pageCount, setPageCount] = useState(0);
	const [itemOffset, setItemOffset] = useState(0);

	const handlePageClick = ({ selected }: { selected: number }) => {
		if (sortedWalletsList) {
			const newOffset = (selected * perPage) % sortedWalletsList.length;
			setItemOffset(newOffset);
		}
	};

	useLayoutEffect(() => {
		if (sortedWalletsList) {
			const endOffset = itemOffset + Number(perPage);
			setCurrentItems(sortedWalletsList.slice(itemOffset, endOffset));
			setPageCount(Math.ceil(sortedWalletsList.length / perPage));
		}
	}, [itemOffset, perPage, sortedWalletsList]);

	return (
		<>
			<div className={`table-title `}>
				<p>{title}</p>
			</div>
			<div
				style={{ marginBottom: pageCount <= 1 && type === ECoinType.FIAT ? '20px' : '0px' }}
				className="table-block table-block--mt-0"
			>
				<div className="table-wrapper">
					<div
						className={`table table--type2 ${
							type === ECoinType.CRYPTO
								? 'table--fiat-balance table--fiat-balance--column table--fiat-balance--custom-mob'
								: 'table--fiat-balance table--fiat-balance--custom table--fiat-balance--custom-mob'
						}`}
					>
						<WalletTableHeader
							type={type}
							sortOrder={sortOrder}
							setSortOrder={setSortOrder}
							sortItem={sortItem}
							setSortItem={setSortItem}
						/>
						<div className="table-body">
							{!!currentItems?.length &&
								currentItems.map((wallet) => {
									return <WalletTableRow key={wallet.id} {...wallet} type={type} />;
								})}
							<div className="pagination-block">
								<ReactPaginate
									pageCount={pageCount}
									onPageChange={handlePageClick}
									breakLabel="..."
									className="pagination"
									activeClassName="active"
									previousClassName="pagination__arrow pagination__arrow--prev"
									nextClassName="pagination__arrow pagination__arrow--next"
									previousLabel=""
									nextLabel=""
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default WalletsTable;
