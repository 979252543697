import { TApiTransactionHistoryStatus } from '../../../services/api/transactions/types';

export const statusClassNames: Record<TApiTransactionHistoryStatus, string> = {
	completed: 'green',
	pending: 'yellow',
	admin_pending: 'yellow',
	failed: 'red',
};

export const statusTitle: Record<TApiTransactionHistoryStatus, string> = {
	completed: 'Completed',
	pending: 'Pending',
	admin_pending: 'Pending',
	failed: 'Failed',
};
