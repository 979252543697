/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
	IActiveBankAccount,
	IBankAccountGetResponse,
	IBankAccountRequestPayload,
	IBankAccountsPageInfo,
	IBankAccountsStore,
} from './types';
import { IDepositAccount } from '../walletBalance/types';
import { currency } from '../../../services/api/currency';

// ==========================================:
export const initialState: IBankAccountsStore = {
	data: {
		current_page: 1,
		data: [],
		first_page_url: '',
		from: 1,
		last_page: 1,
		last_page_url: '',
		links: [],
		next_page_url: '',
		path: '',
		per_page: 5,
		prev_page_url: '',
		to: 1,
		id: 1,
		total: 1,
	},
	userId: 1,
	loading: false,
	activeBankAccounts: null,
	resetActiveBankAccounts: false,
};

// ==========================================:
const bankAccounts = createSlice({
	name: '@@bankAccounts',
	initialState,
	reducers: {
		getBankAccountsRequest: (state) => {
			const newState = state;
			newState.data = {
				current_page: 1,
				data: [],
				first_page_url: '',
				from: 1,
				last_page: 1,
				last_page_url: '',
				links: [],
				next_page_url: '',
				path: '',
				per_page: 5,
				prev_page_url: '',
				to: 1,
				id: 1,
				total: 1,
			};

			newState.loading = true;
		},
		getBankAccountsSuccess: (state, action: PayloadAction<IBankAccountGetResponse>) => {
			const { payload } = action;
			const newState = state;
			newState.data = payload;
			newState.loading = false;
		},
		deleteBankAccountRequest: (state, action: PayloadAction<{ id: number }>) => {
			const newState = state;

			newState.loading = true;
		},
		updateBankAccountRequest: (state, action: PayloadAction<IBankAccountRequestPayload>) => {
			const newState = state;

			newState.loading = true;
		},
		changeBankAccountsPage: (state, action: PayloadAction<IBankAccountsPageInfo>) => {
			const newState = state;
			newState.data.current_page = action.payload.current_page;
			newState.data.per_page = action.payload.per_page;
			newState.loading = true;
		},
		changeBankAccountsUserId: (state, action: PayloadAction<number>) => {
			const newState = state;
			newState.userId = action.payload;
		},
		addActiveBankAccounts: (state, { payload }: PayloadAction<IActiveBankAccount>) => {
			const bankAccountsWithoutDuplicate =
				state.activeBankAccounts?.filter((filter) => filter.asset_code !== payload.asset_code) ||
				[];

			if (state.activeBankAccounts?.length === bankAccountsWithoutDuplicate.length) {
				state.activeBankAccounts = [...state.activeBankAccounts, payload];
			} else {
				state.activeBankAccounts = [...bankAccountsWithoutDuplicate, payload];
			}
		},
		setToInitActiveBankAccounts: (state, { payload }: PayloadAction<IDepositAccount[]>) => {
			if (state.resetActiveBankAccounts) {
				const activeElements = payload?.filter(({ status }) => status);
				state.activeBankAccounts = activeElements?.map((element) => ({
					bank_account_id: element.id,
					asset_code: element.asset?.code || element.currency,
				}));
				state.resetActiveBankAccounts = false;
			}
		},
		deleteActiveBankAccounts: (state, { payload }: PayloadAction<IActiveBankAccount>) => {
			const bankAccountsWithoutDuplicate =
				state.activeBankAccounts?.filter(
					(filter) => filter.bank_account_id !== payload.bank_account_id,
				) || [];

			state.activeBankAccounts = [...bankAccountsWithoutDuplicate];
		},
		activateResetActiveBankAccounts: (state) => {
			state.resetActiveBankAccounts = true;
		},
		resetActiveBankAccounts: (state) => {
			state.activeBankAccounts = null;
		},
	},
});

export default bankAccounts.reducer;
export const {
	getBankAccountsRequest,
	getBankAccountsSuccess,
	deleteBankAccountRequest,
	updateBankAccountRequest,
	changeBankAccountsPage,
	changeBankAccountsUserId,
	addActiveBankAccounts,
	setToInitActiveBankAccounts,
	deleteActiveBankAccounts,
	activateResetActiveBankAccounts,
	resetActiveBankAccounts,
} = bankAccounts.actions;
