/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */

import React, { FC, useCallback, useState } from 'react';
import { Link } from 'react-router-dom';

import StatusUpdatet from 'layouts-elements/PopUp/StatusUpdatet';
import IconSvg from 'ui/Svg/IconSvg';
import { statusClassNames, statusNames } from 'components/AccountDetails/utiils';
import { roundingNumber } from 'services/utils/roundingNumber';
import classNames from 'classnames';
import { IUsersTableRowProps } from '../types';
import { notificationContainer } from '../../../../services/utils/notificationContainer';

const UsersTableRow: FC<IUsersTableRowProps> = ({ user, query, permission }) => {
	const {
		id,
		email,
		blocked,
		is_archived: isArchived,
		status_id: statusId,
		data,
		sumBalance,
	} = user;
	const [openStatusUpdatet, setOpenStatusUpdatet] = useState(false);
	const [eventStatus, setEventStatus] = useState('');
	const closeStatusUpdatet = useCallback(() => {
		setOpenStatusUpdatet(false);
	}, []);

	const handleStatusUpdatet = useCallback(
		(event: string) => {
			if (sumBalance !== 0 && event === 'archive') {
				notificationContainer(
					'User still has funds on his balance. If archived, access to the funds will be lost.<br>In order to archive a user, his platform balance should be 0',
					'error_archiving',
					'User archivation warning',
				);
			} else {
				setOpenStatusUpdatet(true);
				setEventStatus(event);
			}
		},
		[sumBalance],
	);

	return (
		<div className="tr">
			<div className="td">
				<p className="td-hidden-name">User ID</p>
				<p>
					<Link to={{ pathname: `/user-management/${String(id)}`, state: { id } }} className="link">
						{id}
					</Link>
				</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Full Name</p>
				<p>
					{data.first_name} {data.last_name}
				</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">E-mail</p>
				<p>
					<Link to={{ pathname: `/user-management/${String(id)}`, state: { id } }} className="link">
						{email}
					</Link>
				</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Company Name</p>
				<p>{data.company_name}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Total Balance</p>
				<p>{roundingNumber(sumBalance, 'EUR')} EUR</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Status</p>
				{isArchived ? (
					<span className="yellow-bg">Archived</span>
				) : (
					<span className={statusClassNames[statusId]}>{statusNames[statusId]}</span>
				)}
			</div>
			<div className="td">
				<p className="td-hidden-name">Action </p>
				<div className={classNames('table-buttons', { archive: isArchived })}>
					{permission && (
						<>
							{blocked ? (
								<button
									type="button"
									className="unblock"
									onClick={() => handleStatusUpdatet('unblock')}
								>
									<IconSvg name="unblock" w="18" h="18" />
								</button>
							) : (
								<button
									type="button"
									className="block"
									onClick={() => handleStatusUpdatet('block')}
								>
									<IconSvg name="block" w="18" h="18" />
								</button>
							)}
							<button
								type="button"
								className="btn btn--icon-danger btn--delete"
								onClick={() => handleStatusUpdatet('archive')}
								disabled={Boolean(isArchived)}
							/>
						</>
					)}
				</div>
				<StatusUpdatet
					open={openStatusUpdatet}
					closeModal={closeStatusUpdatet}
					event={eventStatus}
					userId={id}
					query={query}
				/>
			</div>
		</div>
	);
};

export default UsersTableRow;
