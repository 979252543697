import React, { useMemo, useState } from 'react';
import { NavLink, Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { ROUTES } from 'routes/constants';
import { EApiTransactionType } from 'services/api/transactions/types';
import AddFiatDepositTransaction from 'layouts-elements/PopUp/AddFiatDepositTransaction/AddFiatDepositTransaction';
import { useSelector } from 'react-redux';
import { getPermissions } from 'redux/reducers/auth/selectors';
import { EPermissionNames } from 'redux/reducers/auth/types';
import CryptoTransactionsTable from '../CryptoTransactionsTable';
import FiatTransactionsTable from '../FiatTransactionsTable';
import PooledDepositTable from '../PooledDepositTable';
import ProfitWalletTable from '../ProfitWalletTable';
import { ITransactionsTab } from '../types';

const DepositsWithdrawalsTab = ({ type }: ITransactionsTab) => {
	const path: { [key: string]: string } = useMemo(() => {
		switch (type) {
			case EApiTransactionType.DEPOSIT: {
				return ROUTES.transfersAndTradeReports.deposit;
			}
			case EApiTransactionType.WITHDRAWAL: {
				return ROUTES.transfersAndTradeReports.withdrawals;
			}
			case EApiTransactionType.TRANSFER: {
				return ROUTES.transfersAndTradeReports.transfers;
			}
			default: {
				return ROUTES.transfersAndTradeReports.deposit;
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [type]);

	const [addNewTransactionPopup, setAddNewTransactionPopup] = useState(false);
	const handleOpenAddNewTransactionPopup = () => {
		setAddNewTransactionPopup(true);
	};
	const handleCloseAddNewTransactionPopup = () => {
		setAddNewTransactionPopup(false);
	};

	const permission = useSelector(getPermissions)?.[EPermissionNames.TRANSFER_TRADE_REPORT].editable;

	const { pathname } = useLocation();

	return (
		<div className="user-management">
			<div className="user-management-body user-management-body--type2">
				<div className={type === EApiTransactionType.DEPOSIT ? 'tabs-buttons-wrapper' : ''}>
					<div
						className={`tabs-buttons tabs-buttons-${
							type === EApiTransactionType.TRANSFER ? 'grid' : 'flex'
						}`}
					>
						<NavLink to={path?.crypto || ''} className="button button--size4 button--type3">
							Crypto
						</NavLink>
						<NavLink to={path?.fiat || ''} className="button button--size4 button--type3">
							Fiat
						</NavLink>
						{type === EApiTransactionType.TRANSFER && (
							<NavLink
								to={path?.pooledDeposit || ''}
								className="button button--size4 button--type3"
							>
								Pooled Deposit
							</NavLink>
						)}
						{type === EApiTransactionType.TRANSFER && (
							<NavLink to={path?.profitWallet || ''} className="button button--size4 button--type3">
								Profit Wallet
							</NavLink>
						)}
					</div>
					{!!permission && type === EApiTransactionType.DEPOSIT && pathname === path.fiat && (
						<button
							type="button"
							onClick={handleOpenAddNewTransactionPopup}
							className="btn btn-primary"
						>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="21"
								height="20"
								viewBox="0 0 21 20"
								fill="none"
							>
								<path
									d="M9.87435 15.8307V10.6224H4.66602V9.3724H9.87435V4.16406H11.1243V9.3724H16.3327V10.6224H11.1243V15.8307H9.87435Z"
									fill="white"
								/>
							</svg>
							Add New Transaction
						</button>
					)}
				</div>

				<Switch>
					<Route exact path={path?.crypto || ''}>
						<CryptoTransactionsTable type={type} />
					</Route>
					<Route exact path={path?.fiat || ''}>
						<FiatTransactionsTable type={type} />
					</Route>
					{type === EApiTransactionType.TRANSFER && (
						<Route exact path={path?.pooledDeposit || ''}>
							<PooledDepositTable />
						</Route>
					)}
					{type === EApiTransactionType.TRANSFER && (
						<Route exact path={path?.profitWallet || ''}>
							<ProfitWalletTable />
						</Route>
					)}
					<Redirect to={path?.crypto || ''} />
				</Switch>
			</div>
			<AddFiatDepositTransaction
				closeModal={handleCloseAddNewTransactionPopup}
				open={addNewTransactionPopup}
			/>
		</div>
	);
};

export default DepositsWithdrawalsTab;
