import { IStoreState } from 'redux/types';
import { ITransactionsStore } from './types';

export const getTransactions = (state: IStoreState): ITransactionsStore => state.transactions;

// 📌 Transaction history

export const getHistoryLoading = (state: IStoreState) => {
	return getTransactions(state).historyLoading;
};

// 📌 Transaction history - Fiat

export const getCryptoHistory = (state: IStoreState) => {
	return getTransactions(state).cryptoHistory;
};

export const getCryptoHistoryList = (state: IStoreState) => {
	return getTransactions(state).cryptoHistory?.data;
};

export const getCryptoHistoryPageCount = (state: IStoreState) => {
	return getTransactions(state).cryptoHistory?.last_page || 1;
};

// 📌 Transaction history - fiat

export const getFiatHistory = (state: IStoreState) => {
	return getTransactions(state).fiatHistory;
};

export const getFiatHistoryList = (state: IStoreState) => {
	return getTransactions(state).fiatHistory?.data;
};

export const getFiatHistoryPageCount = (state: IStoreState) => {
	return getTransactions(state).fiatHistory?.last_page || 1;
};

// 📌 Transaction history - trade

export const getTradeHistory = (state: IStoreState) => {
	return getTransactions(state).tradeHistory;
};

export const getTradeHistoryList = (state: IStoreState) => {
	return getTransactions(state).tradeHistory?.data;
};

export const getTradeHistoryPageCount = (state: IStoreState) => {
	return getTransactions(state).tradeHistory?.last_page || 1;
};

// 📌 Transactions

export const getCryptoTransactions = (state: IStoreState) => {
	return getTransactions(state).cryptoTransactions;
};

export const getCryptoTransactionsLoading = (state: IStoreState) => {
	return getTransactions(state).cryptoTransactionsLoading;
};
export const getCryptoTransactionsLdLoading = (state: IStoreState) => {
	return getTransactions(state).cryptoTransactionsLdLoading;
};

export const getFiatTransactions = (state: IStoreState) => {
	return getTransactions(state).fiatTransactions;
};

export const getFiatTransactionsLoading = (state: IStoreState) => {
	return getTransactions(state).fiatTransactionsLoading;
};

export const getPooledDeposits = (state: IStoreState) => {
	return getTransactions(state).pooledDeposits;
};

export const getPooledDepositsLoading = (state: IStoreState) => {
	return getTransactions(state).pooledDepositsLoading;
};

export const getWithdrawalLimits = (state: IStoreState) => {
	return getTransactions(state).withdrawalLimits;
};

export const getWithdrawalLimitsLoading = (state: IStoreState) => {
	return getTransactions(state).withdrawalLimitsLoading;
};

export const getWithdrawalGeneralLimit = (state: IStoreState) => {
	return getTransactions(state).withdrawalGeneralLimit;
};

export const getWithdrawalGeneralLimitLoading = (state: IStoreState) => {
	return getTransactions(state).withdrawalGeneralLimitLoading;
};

export const getTrades = (state: IStoreState) => {
	return getTransactions(state).trades;
};

// new
export const getAllBankAccount = (state: IStoreState) => {
	return getTransactions(state).allBankAccount;
};

export const getTradesLoading = (state: IStoreState) => {
	return getTransactions(state).tradesLoading;
};

export const getTradesLimits = (state: IStoreState) => {
	return getTransactions(state).tradesLimits;
};

export const getTradesLimitsLoading = (state: IStoreState) => {
	return getTransactions(state).tradesLimitsLoading;
};

export const getProfitWallets = (state: IStoreState) => {
	return getTransactions(state).profitWallets;
};

export const getProfitWalletsLoading = (state: IStoreState) => {
	return getTransactions(state).profitWalletsLoading;
};

export const getPendingWithdrawal = (state: IStoreState) => {
	return getTransactions(state).pendingWithdrawal;
};

export const getPendingWithdrawalLoading = (state: IStoreState) => {
	return getTransactions(state).pendingWithdrawalLoading;
};
