/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { ChangeEvent, FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { useFormatDateTime } from 'hooks/useFormatDateTime';
import {
	EApiPooledDepositTransactionStatus,
	EApiTransactionStatus,
	EPendingWithdrawalType,
	IPendingWithdrawal,
} from 'services/api/transactions/types';
import {
	putPendingWithdrawalApproveRequest,
	putPendingWithdrawalNoteRequest,
	putPendingWithdrawalRejectRequest,
} from 'redux/reducers/transactions/reducer';
import { IHistoryFiltersOptionStringValue } from 'components/AccountDetails/TransactionHistoryTab/CryptoHistory/types';
import { CheckIcon, CloseIcon, PencilIcon } from 'assets/inline-svg';
import TableItemSelect from 'ui/TableItemSelect/TableItemSelect';
import { roundingNumber } from 'services/utils/roundingNumber';
import { shortNotation } from 'services/utils/strings';
import CopyToClipboard from 'react-copy-to-clipboard';
import { notificationContainer } from 'services/utils/notificationContainer';

const STATUS_TO_EDIT_CRYPTO = [
	{
		id: 1,
		name: 'Approved',
		value: EApiPooledDepositTransactionStatus.COMPLETED,
	},
	{
		id: 2,
		name: 'Failed',
		value: EApiPooledDepositTransactionStatus.FAILED,
	},
];
const STATUS_TO_EDIT_FIAT = [
	{
		id: 1,
		name: 'Completed',
		value: EApiPooledDepositTransactionStatus.COMPLETED,
	},
	{
		id: 2,
		name: 'Failed',
		value: EApiPooledDepositTransactionStatus.FAILED,
	},
];

export interface IPendingWithdrawalTableRow extends IPendingWithdrawal {
	permission: boolean;
	assetType: EPendingWithdrawalType;
}

const PendingDepositsTableRow: FC<any> = ({
	id,
	status,
	created_at,
	asset,
	amount,
	type,
	user,
	permission,
	note,
	assetType,
	admin,
	tx_hash,
	admin_id,
}) => {
	const dispatch = useDispatch();
	const [date, time] = useFormatDateTime(created_at);
	const statusToEditArray =
		assetType === EPendingWithdrawalType.FIAT ? STATUS_TO_EDIT_FIAT : STATUS_TO_EDIT_CRYPTO;

	const filterStatusArrayToEdit = (currentStatus: EApiPooledDepositTransactionStatus | string) => {
		return statusToEditArray.filter((el) => el.value !== currentStatus);
	};
	const findEditedStatus = (currentStatus: string) => {
		return statusToEditArray.find((el) => el.value === currentStatus) || statusToEditArray[0];
	};

	const [editedNote, setEditedNote] = useState(note);
	const handleChangeEditedNote = (event: ChangeEvent<HTMLInputElement>) => {
		setEditedNote(event.target.value || null);
	};

	const [editedStatus, setEditedStatus] = useState<IHistoryFiltersOptionStringValue>({
		id: 0,
		value: EApiPooledDepositTransactionStatus.ADMIN_PENDING,
		name: 'Pending',
	});
	const [statusArrayToEdit, setStatusArrayToEdit] = useState<IHistoryFiltersOptionStringValue[]>(
		filterStatusArrayToEdit(status),
	);
	const handleEditStatus = (activeElement: IHistoryFiltersOptionStringValue) => {
		setEditedStatus(activeElement);
		setStatusArrayToEdit(filterStatusArrayToEdit(activeElement.value));
	};

	const [editMode, setEditMode] = useState(false);
	const enableEditMode = () => setEditMode(true);
	const disableEditMode = () => setEditMode(false);

	const handleEdit = () => {
		if (editedStatus.value === EApiPooledDepositTransactionStatus.FAILED) {
			dispatch(putPendingWithdrawalRejectRequest({ type: assetType, id }));
		}
		if (editedStatus.value === EApiPooledDepositTransactionStatus.COMPLETED) {
			dispatch(putPendingWithdrawalApproveRequest({ type: assetType, id }));
		}
		if (editedNote !== note) {
			dispatch(
				putPendingWithdrawalNoteRequest({
					type: assetType,
					data: { transaction_id: id, note: editedNote || '' },
				}),
			);
		}
		disableEditMode();
	};
	const handleDisableEditMode = () => {
		setEditedStatus(findEditedStatus(status));
		disableEditMode();
	};

	const convertStatusValue = (currentStatus: EApiTransactionStatus) => {
		switch (currentStatus) {
			case EApiTransactionStatus.FAILED:
				return 'Failed';
			case EApiTransactionStatus.CANCELED:
				return 'Failed';
			case EApiTransactionStatus.REJECTED:
				return 'Failed';
			case EApiTransactionStatus.ADMIN_PENDING:
				return 'Pending';
			case EApiTransactionStatus.SUCCESS:
				return 'Completed';
			case EApiTransactionStatus.COMPLETED:
				return 'Completed';
			default:
				return `${currentStatus[0].toUpperCase()}${currentStatus.slice(1)}`;
		}
	};
	const [full, from, to] = type.match(/From\s+(.*?)\s+To\s+(.*)/);

	const handleCopy = () => {
		notificationContainer('Сopied to clipboard.', 'info');
	};
	return (
		<div className={`tr ${editMode ? 'tr--editable' : ''}`}>
			<div className="td">
				<p className="td-hidden-name">Timestamp</p>
				<p className="td-name--flex">{date}</p>
				<span className="td-more-info">{time.replaceAll('-', ':')}</span>
			</div>
			<div className="td">
				<p className="td-hidden-name">User ID</p>
				<p>{assetType === EPendingWithdrawalType.FIAT ? admin_id : user?.id || '-'}</p>
			</div>
			<div className="td">
				<p className="td-hidden-name">Currency</p>
				<p className="td-name--flex">{asset.code.toUpperCase()}</p>
				<span className="td-more-info">{asset?.name}</span>
			</div>
			<div className="td">
				<p className="td-hidden-name">Amount</p>
				<p>{roundingNumber(amount, assetType)}</p>
				<span className="td-more-info">{asset.code.toUpperCase()}</span>
			</div>

			<div className="td">
				<p className="td-hidden-name">Type</p>
				<p
					className={
						assetType === EPendingWithdrawalType.CRYPTO ? 'td--width-150' : 'td--width-130'
					}
				>
					From <span className="td-bold-text">{from}</span>
				</p>
				<p
					className={
						assetType === EPendingWithdrawalType.CRYPTO ? 'td--width-150' : 'td--width-130'
					}
				>
					To <span className="td-bold-text">{to}</span>
				</p>
			</div>
			{assetType === EPendingWithdrawalType.CRYPTO && (
				<>
					<p className="td-hidden-name">Tx Hash</p>
					<p className="copy-button">
						<span className="copy-button__text copy-button__text--max-width">
							{shortNotation(tx_hash, 4, 4) || '-'}
						</span>
						{!!tx_hash && (
							<CopyToClipboard text={tx_hash.toString()} onCopy={handleCopy}>
								<button type="button">
									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="15"
										height="14"
										viewBox="0 0 15 14"
										fill="none"
									>
										<path
											d="M3.29199 12.8151C3.05866 12.8151 2.85449 12.7276 2.67949 12.5526C2.50449 12.3776 2.41699 12.1734 2.41699 11.9401V3.14635H3.29199V11.9401H10.2045V12.8151H3.29199ZM5.04199 11.0651C4.80866 11.0651 4.60449 10.9776 4.42949 10.8026C4.25449 10.6276 4.16699 10.4234 4.16699 10.1901V2.02344C4.16699 1.7901 4.25449 1.58594 4.42949 1.41094C4.60449 1.23594 4.80866 1.14844 5.04199 1.14844H11.4587C11.692 1.14844 11.8962 1.23594 12.0712 1.41094C12.2462 1.58594 12.3337 1.7901 12.3337 2.02344V10.1901C12.3337 10.4234 12.2462 10.6276 12.0712 10.8026C11.8962 10.9776 11.692 11.0651 11.4587 11.0651H5.04199ZM5.04199 10.1901H11.4587V2.02344H5.04199V10.1901Z"
											fill="#777E90"
										/>
									</svg>
								</button>
							</CopyToClipboard>
						)}
					</p>
				</>
			)}
			{/* <div className="td">
				<p className="td-hidden-name">Admin Notes</p>
				{editMode ? (
					<div className="input">
						<div className="input-wrapper">
							<input
								type="text"
								placeholder="Notes"
								value={editedNote || ''}
								onChange={handleChangeEditedNote}
								className="input-item input-item--type-td"
							/>
						</div>
					</div>
				) : (
					<div className="tooltip-item-note">
						<p className="tooltip-item-text">{note ? `${note?.slice(0, 10) as string}...` : '-'}</p>
						<div className="tooltip-note">
							<p>{note || '-'}</p>
							<i />
						</div>
					</div>
				)}
			</div> */}
			<div className="td">
				<p className="td-hidden-name">Status</p>
				{editMode && status === EApiTransactionStatus.ADMIN_PENDING ? (
					<TableItemSelect
						activeElement={editedStatus}
						selectArray={statusArrayToEdit}
						onChange={handleEditStatus}
					/>
				) : (
					<span
						className={
							status === EApiTransactionStatus.ADMIN_PENDING
								? 'pending-status'
								: `${String(status)}-status`
						}
					>
						{convertStatusValue(status)}
					</span>
				)}
			</div>
			<div className="td td--right">
				<p className="td-hidden-name">Action</p>
				{permission && editMode && (
					<div className="table-buttons flex-e">
						<button
							type="button"
							className="btn--icon-danger btn--cancel"
							onClick={handleDisableEditMode}
						/>
						<button
							type="button"
							className="btn--icon-success btn--check"
							onClick={handleEdit}
							disabled={
								editedStatus.value === EApiPooledDepositTransactionStatus.ADMIN_PENDING &&
								note === editedNote
							}
						/>
					</div>
				)}
				{permission && !editMode && (
					// eslint-disable-next-line jsx-a11y/control-has-associated-label, react/self-closing-comp
					<button
						type="button"
						className="btn btn--icon btn--edit"
						onClick={enableEditMode}
						disabled={status !== EApiTransactionStatus.ADMIN_PENDING}
					></button>
				)}
			</div>
		</div>
	);
};

export default PendingDepositsTableRow;
