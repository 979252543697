import { FC } from 'react';
import { useRouteMatch } from 'react-router';
import { NavLink } from 'react-router-dom';
import { ROUTES } from 'routes/constants';

interface IPropsAccountTabs {
	userId: string;
}

const AccountTabs: FC<IPropsAccountTabs> = ({ userId }) => {
	const { url } = useRouteMatch();

	return (
		<div className="tabs-account mb-0">
			<NavLink to={`${url}${ROUTES.AccountTabs.ACCOUNT}`}>Account</NavLink>
			<NavLink to={{ pathname: `${url}${ROUTES.AccountTabs.BALANCES}`, state: { id: userId } }}>
				Balances
			</NavLink>
			<NavLink to={`${url}${ROUTES.AccountTabs.DEPOSIT_HISTORY}`}>Deposit History</NavLink>
			<NavLink to={`${url}${ROUTES.AccountTabs.WITHDRAWAL_HISTORY}`}>Withdrawal History</NavLink>
			<NavLink to={`${url}${ROUTES.AccountTabs.TRANSFER_HISTORY}`}>Transfer History</NavLink>
			<NavLink to={`${url}${ROUTES.AccountTabs.TRADE_HISTORY}`}>Trade History</NavLink>
			<NavLink to={`${url}${ROUTES.AccountTabs.REFERRAL}`}>Referral</NavLink>
			<NavLink to={`${url}${ROUTES.AccountTabs.TRADING_LIMITS}`}>Trade Limits</NavLink>
			<NavLink to={`${url}${ROUTES.AccountTabs.PAYMENT_METHOD}`}>Beneficiaries</NavLink>
			<NavLink to={`${url}${ROUTES.AccountTabs.TRADE_MANAGEMENT}`}>Liquidity</NavLink>
			<NavLink to={`${url}${ROUTES.AccountTabs.FEE_MANAGEMENT}`}>Fee Management</NavLink>
			<NavLink to={`${url}${ROUTES.AccountTabs.CURRENCIES_EMAILS}`}>Currencies</NavLink>
			<NavLink to={`${url}${ROUTES.AccountTabs.REQUIRED_EMAILS}`}>Emails</NavLink>
			<NavLink to={`${url}${ROUTES.AccountTabs.REFERRAL_FEE}`}>Referral Fee</NavLink>
		</div>
	);
};

export default AccountTabs;
