import { createSelector } from '@reduxjs/toolkit';
import { IStoreState } from 'redux/types';
import { IUsersStore, IUserWalletsData, IUserTradingLimitsData } from './types';

export const getUsers = (state: IStoreState): IUsersStore => state.users;

export const getUsersData = createSelector([getUsers], (users: IUsersStore) => users.users);
export const getUsersDataLoading = createSelector([getUsers], (users: IUsersStore) => users.loader);

// 📌 One user

export const getOneUser = (state: IStoreState) => {
	return getUsers(state).oneUser;
};

export const getOneUserLoading = (state: IStoreState) => {
	return getUsers(state).oneUserLoading;
};

export const getUserWallets = createSelector(
	[getUsers],
	(users: IUsersStore) => users.userWalletsList,
);

export const getUserWalletsCrypto = createSelector(
	[getUsers],
	(users: IUsersStore): IUserWalletsData =>
		users.userWalletsList
			? users.userWalletsList.wallets.filter(
					(el) => el.asset.type === 'crypto' || el.asset.type === 'token',
			  )
			: [],
);

export const getUserWalletsFiat = createSelector(
	[getUsers],
	(users: IUsersStore): IUserWalletsData =>
		users.userWalletsList
			? users.userWalletsList.wallets.filter((el) => el.asset.type === 'fiat')
			: [],
);

export const getUserReferral = (state: IStoreState) => {
	return getUsers(state).userReferralList;
};

export const getUserReferralLoader = (state: IStoreState) => {
	return getUsers(state).loader;
};

export const getUserLoader = (state: IStoreState) => {
	return getUsers(state).loader;
};

export const getUserReferralsPageCount = (state: IStoreState) => {
	return getUsers(state).userReferralList?.last_page || 1;
};

export const getUserTradingLimits = (state: IStoreState) => {
	return getUsers(state).userTradingLimitsList;
};
export const getReferralSystem = (state: any) => {
	return getUsers(state).referralSystem;
};
// export const getUserCryptoTradingLimits = createSelector(
// 	[getUsers],
// 	(users: IUsersStore): IUserTradingLimitsData =>
// 		users?.userTradingLimitsList
// 			? users?.userTradingLimitsList?.filter((el) => el.asset.type !== 'fiat')
// 			: [],
// );

// export const getUserFiatTradingLimits = createSelector(
// 	[getUsers],
// 	(users: IUsersStore): IUserTradingLimitsData =>
// 		users?.userTradingLimitsList
// 			? users?.userTradingLimitsList?.filter((el) => el.asset.type === 'fiat')
// 			: [],
// );

export const getRequests = createSelector([getUsers], (users: IUsersStore) => users.resetRequests);
export const getRequestsLoading = createSelector([getUsers], (users: IUsersStore) => users.loader);

export const getRequiredEmails = createSelector(
	[getUsers],
	(users: IUsersStore) => users.requiredEmails,
);
export const getRequiredEmailsLoading = createSelector(
	[getUsers],
	(users: IUsersStore) => users.requiredEmailsLoading,
);
export const getAdminResetPasswordsRequests = createSelector(
	[getUsers],
	(users: IUsersStore) => users.adminPasswordResetRequests,
);

export const getAdminResetPasswordsRequestsLoading = createSelector(
	[getUsers],
	(users: IUsersStore) => users.adminPasswordResetRequestsLoading,
);

export const getAdminTwoFaResetRequests = createSelector(
	[getUsers],
	(users: IUsersStore) => users.adminTwoFaResetRequests,
);
// 📌 One user fee
export const getUsersFees = createSelector([getUsers], (users: IUsersStore) => users.userFees);
export const getUsersFeesLoader = createSelector(
	[getUsers],
	(users: IUsersStore) => users.oneUserFeesLoading,
);
export const getUsersSpreadFees = createSelector(
	[getUsers],
	(users: IUsersStore) => users.userFeesSpread,
);
export const getUsersSpreadFeesLoading = createSelector(
	[getUsers],
	(users: IUsersStore) => users.oneUserFeesSpreadLoading,
);
export const getUsersPasswordResetRequests = createSelector(
	[getUsers],
	(users: IUsersStore) => users.passwordResetRequests,
);
// create Account
export const getUserCreateData = createSelector(
	[getUsers],
	(users: IUsersStore) => users.createUserData,
);

// switch User /Admin
export const getUserAdmin = createSelector([getUsers], (users: IUsersStore) => users.mailSwitcher);

export const getFireblockIdLoading = (state: IStoreState) => {
	return getUsers(state).setFireblockIdLoading;
};
