import React from 'react';
import { NavLink, Redirect, Route, Switch } from 'react-router-dom';
import { ROUTES } from 'routes/constants';
import { EPendingWithdrawalType } from 'services/api/transactions/types';
import PendingWithdrawalTable from './PendingWithdrawalTable';

const PendingWithdrawalTab = () => (
	<div className="user-management">
		<div className="currencyButtonFile mb-20">
			<div className="currencyButton">
				<NavLink to={ROUTES.transfersAndTradeReports.pendingWithdrawal.crypto}>Crypto</NavLink>
				<NavLink to={ROUTES.transfersAndTradeReports.pendingWithdrawal.fiat}>Fiat</NavLink>
			</div>
		</div>

		<Switch>
			<Route exact path={ROUTES.transfersAndTradeReports.pendingWithdrawal.crypto}>
				<PendingWithdrawalTable type={EPendingWithdrawalType.CRYPTO} />
			</Route>
			<Route exact path={ROUTES.transfersAndTradeReports.pendingWithdrawal.fiat}>
				<PendingWithdrawalTable type={EPendingWithdrawalType.FIAT} />
			</Route>
			<Redirect to={ROUTES.transfersAndTradeReports.pendingWithdrawal.crypto} />
		</Switch>
	</div>
);

export default PendingWithdrawalTab;
