import React, { FC, useLayoutEffect } from 'react';
import cn from 'classnames';
import { ReactComponent as UncrossedEyeIcon } from 'assets/img/admin/login/uncrossedEye.svg';
import { ReactComponent as CrossedOutEyeIcon } from 'assets/img/admin/login/crossedOutEye.svg';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { IAdminFormConrol } from './types';
// import { getErrorMsg } from '../../../redux/reducers/errors/selectors';

// ==========================================:
const FormControl: FC<IAdminFormConrol> = (props) => {
	const {
		form,
		field,
		isShowPass,
		setIsShowPass,
		title,
		children,
		ariaLabel,
		fieldIcon,
		fieldIconClassName,
		inputNotification,
		link,
		linkTitle,
		errorMessage,
	} = props;

	// const errorMsg = useSelector(getErrorMsg);
	const errorMsg = useSelector((state: any) => state.error.errorMsg);
	const errors: string = form?.errors?.[field?.name];
	const touched: string = form?.touched?.[field.name];

	const handlePassDisplay = (): void => {
		if (setIsShowPass) {
			setIsShowPass(!isShowPass);
		}
	};

	let inputStatus = '';

	if (errors && touched) {
		inputStatus = 'input--error';
	} else if (!errors && touched) {
		inputStatus = 'input--success';
	} else {
		inputStatus = '';
	}

	// useLayoutEffect(() => {
	// 	if (errorMsg) {
	// 		console.log(errorMsg);
	// 	}
	// }, [errorMsg]);

	useLayoutEffect(() => {
		if (field) {
			if (field.name === 'password' && errorMsg === 'invalid_credentials') {
				form.setFieldError('password', 'Your password is incorrect.');
			} else if (field.name === 'totp' && errorMsg === 'the_totp_field_is_required') {
				form.setFieldError('totp', 'Please enter 2FA code.');
			} else if (field.name === 'totp' && errorMsg === 'invalid_totp_code') {
				form.setFieldError('totp', 'Your 2FA code is incorrect.');
			} else if (field.name === 'totp' && errorMsg === 'Invalid Authentication Code.') {
				form.setFieldError('totp', 'Your 2FA code is incorrect.');
			} else if (field.name === 'email' && errorMsg === 'invalid_email') {
				form.setFieldError('email', 'No account is associated with this email.');
			}
			console.log(errorMsg);
		}
		// console.log(errorMsg);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [errorMsg]);

	console.log(errorMessage);

	return (
		<div className={cn('input', inputStatus)}>
			<label htmlFor={title}>
				{title && <p className="input__name">{title}</p>}
				<div className={cn('input-wrapper', { 'input--error': errors && touched })}>
					{children}
					{setIsShowPass && (
						<button
							type="button"
							onClick={handlePassDisplay}
							aria-label={ariaLabel}
							className="show-pass"
						>
							{isShowPass ? (
								<>
									<svg
										width="22"
										height="22"
										viewBox="0 0 22 22"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M14.2821 11.0004C14.2821 12.8154 12.8154 14.2821 11.0004 14.2821C9.18542 14.2821 7.71875 12.8154 7.71875 11.0004C7.71875 9.18542 9.18542 7.71875 11.0004 7.71875C12.8154 7.71875 14.2821 9.18542 14.2821 11.0004Z"
											stroke="#212C63"
											strokeWidth="1.5"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
										<path
											d="M11.0008 18.5849C14.2367 18.5849 17.2525 16.6782 19.3517 13.3782C20.1767 12.0857 20.1767 9.91323 19.3517 8.62073C17.2525 5.32073 14.2367 3.41406 11.0008 3.41406C7.765 3.41406 4.74917 5.32073 2.65 8.62073C1.825 9.91323 1.825 12.0857 2.65 13.3782C4.74917 16.6782 7.765 18.5849 11.0008 18.5849Z"
											stroke="#212C63"
											strokeWidth="1.5"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
									</svg>
								</>
							) : (
								<>
									<svg
										width="22"
										height="22"
										viewBox="0 0 22 22"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											d="M13.3196 8.68125L8.68125 13.3196C8.08542 12.7237 7.71875 11.9079 7.71875 11.0004C7.71875 9.18542 9.18542 7.71875 11.0004 7.71875C11.9079 7.71875 12.7237 8.08542 13.3196 8.68125Z"
											stroke="#212C63"
											strokeWidth="1.5"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
										<path
											d="M16.3358 5.29187C14.7317 4.08187 12.8983 3.42188 11.0008 3.42188C7.765 3.42188 4.74917 5.32854 2.65 8.62854C1.825 9.92104 1.825 12.0935 2.65 13.386C3.37417 14.5227 4.2175 15.5035 5.13417 16.2919"
											stroke="#212C63"
											strokeWidth="1.5"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
										<path
											d="M7.71875 17.899C8.76375 18.339 9.87292 18.5773 11.0004 18.5773C14.2362 18.5773 17.2521 16.6706 19.3512 13.3706C20.1762 12.0781 20.1762 9.90562 19.3512 8.61312C19.0487 8.13646 18.7187 7.68729 18.3796 7.26562"
											stroke="#212C63"
											strokeWidth="1.5"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
										<path
											d="M14.2178 11.6406C13.9795 12.9331 12.9253 13.9873 11.6328 14.2256"
											stroke="#212C63"
											strokeWidth="1.5"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
										<path
											d="M8.67953 13.3203L1.83203 20.1678"
											stroke="#212C63"
											strokeWidth="1.5"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
										<path
											d="M20.1678 1.83594L13.3203 8.68344"
											stroke="#212C63"
											strokeWidth="1.5"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
									</svg>
								</>
							)}
						</button>
					)}
					<span className={cn('input-icon', fieldIconClassName)}>{fieldIcon}</span>
				</div>
				{(!!link || (errors && touched)) && !errorMessage && (
					<div className="input-notify">
						<div className="input__notification input__notification--type2">
							<p>{errors && touched && errors}</p>
							{link && <Link to={link}>{linkTitle}</Link>}
						</div>
					</div>
				)}
				{!!inputNotification && <div className="input-notification">{inputNotification}</div>}
			</label>
		</div>
	);
};

export default FormControl;
