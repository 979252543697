import { call, put, takeEvery, select } from 'redux-saga/effects';
import { hideLoading, showLoading } from 'react-redux-loading-bar';
import { PayloadAction } from '@reduxjs/toolkit';
import { api } from 'services';
import { notificationContainer } from 'services/utils/notificationContainer';
import { popUpOpen, setPopUpData } from 'redux/reducers/popUp/reducer';
import {
	changeBankAccountsPage,
	getBankAccountsRequest,
	updateBankAccountRequest,
	deleteBankAccountRequest,
	getBankAccountsSuccess,
} from './reducer';
import {
	IBankAccountsPageInfo,
	IBankAccountGetResponse,
	IBankAccountRequestPayload,
	IBankAccountUpdateRequestPayload,
} from './types';
import { getBankAccountsPageInfo, getBankAccountsUserId } from './selectors';
import { notificationsMessagesPopUp } from '../../../services/utils/notificationsMessages/notificationsMessagesPopUp';

// =============================================================:

// =============================================================:
function* getBankAccountsRequestWorker() {
	try {
		yield put(showLoading());
		const userId: number = yield select(getBankAccountsUserId);
		const pageInfo: IBankAccountsPageInfo = yield select(getBankAccountsPageInfo);
		const response: IBankAccountGetResponse = yield call(api.bankAccounts.getBankAccounts, {
			userId,
			pageInfo,
		});
		yield put(getBankAccountsSuccess(response));
	} catch (error) {
		notificationContainer('Bank accounts were not found.', 'error');
	} finally {
		yield put(hideLoading());
	}
}
// =============================================================:
function* deleteBankAccountsRequestWorker(action: PayloadAction<{ id: number }>) {
	const { payload } = action;
	try {
		yield put(showLoading());
		const userId: number = yield select(getBankAccountsUserId);
		yield call(api.bankAccounts.deleteBankAccount, { userId, bank_account_id: payload.id });
		yield put(getBankAccountsRequest());
		yield put(popUpOpen('successPopUp'));
	} catch (error) {
		yield put(setPopUpData({ message: notificationsMessagesPopUp.bankDelete }));
		yield put(popUpOpen('errorPopUp'));
	} finally {
		yield put(hideLoading());
	}
}
// ===========================================================:
function* updateBankAccountsRequestWorker(action: PayloadAction<IBankAccountUpdateRequestPayload>) {
	const { payload } = action;
	try {
		yield put(showLoading());
		const userId: number = yield select(getBankAccountsUserId);
		const payloadUserId = { ...payload, userId };

		yield call(api.bankAccounts.updateBankAccount, { ...payloadUserId });
		yield put(getBankAccountsRequest());

		yield put(
			setPopUpData({
				title: 'Bank Account updated',
				message: notificationsMessagesPopUp.bankSuccessfully(payload?.data?.bank_name),
			}),
		);

		yield put(popUpOpen('successPopUp'));
	} catch (error) {
		yield put(setPopUpData({ message: notificationsMessagesPopUp.bankNotUpdate }));
		yield put(popUpOpen('errorPopUp'));
	} finally {
		yield put(hideLoading());
	}
}
// =============================================================:
export function* bankAccountsSaga() {
	yield takeEvery(getBankAccountsRequest.type, getBankAccountsRequestWorker);
	yield takeEvery(updateBankAccountRequest.type, updateBankAccountsRequestWorker);
	yield takeEvery(deleteBankAccountRequest.type, deleteBankAccountsRequestWorker);
	yield takeEvery(changeBankAccountsPage.type, getBankAccountsRequestWorker);
}
