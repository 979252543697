import React, { FC, useState } from 'react';
import FilterSelect from 'ui/Formik/Select/FilterSelect';
import SortedButton, { TSortedDirection } from 'ui/SortedButton';
import { ECryptoSortedFields, EFiatSortedFields } from 'services/api/transactions/types';
import { useHistory } from 'react-router';
import { IHistoryHeaderProps, THistoryFiltersTypeOption } from '../../CryptoHistory/types';
import { statusOptions, typeOptions } from '../../CryptoHistory/utils';

const FiatHistoryHeader: FC<IHistoryHeaderProps> = ({ setSorted, selectedOptions, onChange }) => {
	const handleTypeChange = (typeSelected: THistoryFiltersTypeOption) => {
		onChange({ ...selectedOptions, type: typeSelected });
	};

	const [sortedGross, setSortedGross] = useState<TSortedDirection>('');
	const [sortedFee, setSortedFee] = useState<TSortedDirection>('');
	const [sortedNet, setSortedNet] = useState<TSortedDirection>('');
	const history = useHistory();

	const onSortedGross = (direction: TSortedDirection) => {
		if (direction) {
			!!setSorted && setSorted({ type: EFiatSortedFields.GROSS, value: direction });
		} else {
			!!setSorted && setSorted(null);
		}
		setSortedGross(direction);
		setSortedFee('');
		setSortedNet('');
	};

	const onSortedFee = (direction: TSortedDirection) => {
		if (direction) {
			!!setSorted && setSorted({ type: ECryptoSortedFields.FEE, value: direction });
		} else {
			!!setSorted && setSorted(null);
		}
		setSortedFee(direction);
		setSortedNet('');
		setSortedGross('');
	};

	const onSortedNet = (direction: TSortedDirection) => {
		if (direction) {
			!!setSorted && setSorted({ type: ECryptoSortedFields.NET, value: direction });
		} else {
			!!setSorted && setSorted(null);
		}
		setSortedNet(direction);
		setSortedFee('');
		setSortedGross('');
	};

	return (
		<div className="tr">
			<div className="td">
				<div className="td-name">
					<p>Timestamp</p>
				</div>
			</div>
			<div className="td">
				<div className="td-name td--width_90">
					<p>Currency</p>
				</div>
			</div>
			<div className="td">
				<div className="td-name">
					<SortedButton direction={sortedGross} title="Gross" handleSort={onSortedGross} />
				</div>
			</div>
			<div className="td">
				<div className="td-name">
					<SortedButton direction={sortedFee} title="Fee" handleSort={onSortedFee} />
				</div>
			</div>
			<div className="td">
				<SortedButton direction={sortedNet} title="Net" handleSort={onSortedNet} />
			</div>
			<div className="td">
				<div className="td-name">
					<p>
						{history.location.pathname.includes('deposit-history') ? 'Account Name' : 'Beneficiary'}
					</p>
				</div>
			</div>
			<div className="td td--center">
				<div className="td-name">
					<p>Tx ID</p>
				</div>
			</div>
			<div className="td td--center">
				<div className="td-name">
					<p>Status</p>
				</div>
			</div>
		</div>
	);
};

export default FiatHistoryHeader;
