import React from 'react';
import { NavLink, Redirect, Route, Switch } from 'react-router-dom';
import { ROUTES } from 'routes/constants';
import { EPendingDepositsType } from 'services/api/transactions/types';
import PendingDepositsTable from './PendingDepositsTable';

const PendingDepositsTab = () => (
	<div className="user-management">
		<div className="currencyButtonFile mb-20">
			<div className="currencyButton">
				<NavLink to={ROUTES.transfersAndTradeReports.pendingDeposits.crypto}>Crypto</NavLink>
				<NavLink to={ROUTES.transfersAndTradeReports.pendingDeposits.fiat}>Fiat</NavLink>
			</div>
		</div>

		<Switch>
			<Route exact path={ROUTES.transfersAndTradeReports.pendingDeposits.crypto}>
				<PendingDepositsTable type={EPendingDepositsType.CRYPTO} />
			</Route>
			<Route exact path={ROUTES.transfersAndTradeReports.pendingDeposits.fiat}>
				<PendingDepositsTable type={EPendingDepositsType.FIAT} />
			</Route>
			<Redirect to={ROUTES.transfersAndTradeReports.pendingDeposits.crypto} />
		</Switch>
	</div>
);

export default PendingDepositsTab;
