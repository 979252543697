import React, { useLayoutEffect, useRef, useState } from 'react';
import { Field, Form, Formik } from 'formik';
import PopUp from 'layouts-elements/PopUp/PopUp';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import { popUpOpen } from 'redux/reducers/popUp/reducer';
import { forgotPasswordRequest } from 'redux/reducers/auth/reducer';
import { getForgotPassSuccessPopupOpen } from 'redux/reducers/auth/selectors';
import { ReactComponent as EmailIcon } from 'assets/img/admin/login/email.svg';
import { ReactComponent as PadlockIcon } from 'assets/img/admin/login/padlock.svg';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import ReCAPTCHA from 'react-google-recaptcha';
import Input from 'ui/Formik/Input';
import { Link } from 'react-router-dom';
import { navListBeforeLogin } from 'routes/routesList';
import { Helmet } from 'react-helmet';
import loginBannerImage from '../../assets/dist/img/login-banner@2x-min.png';
import { notificationContainer } from '../../services/utils/notificationContainer';
import InputTotp from '../../ui/Formik/InputTotp';

interface IResetPasswordSubmitValue {
	email: string;
	totp: string;
	reason: string;
}

const ResetPasswordPage = () => {
	const siteKey = process.env.REACT_APP_GOOGLE_CAPTCHA;
	const [bannerPositionLeft, setBannerPositionLeft] = useState(1);
	const [reCaptchaKey, setReCaptchaKey] = useState<string | null>(null);
	const reCaptchaRef = useRef<ReCAPTCHA>(null);
	const dispatch = useDispatch();
	const forgotPassSuccessPopupOpen = useSelector(getForgotPassSuccessPopupOpen);

	const onReCaptchaChange = (key: string | null) => {
		setReCaptchaKey(key);
	};

	const onSubmit = (obg: IResetPasswordSubmitValue) => {
		if (!reCaptchaKey) {
			notificationContainer('Please complete the captcha field.', 'user_blocked', 'Captcha Error');
		} else {
			dispatch(forgotPasswordRequest(obg));
		}
	};

	useLayoutEffect(() => {
		const bodyRect = document.body.getBoundingClientRect();
		const loginBanner = document.getElementById('login_banner');
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		const loginBannerRect = loginBanner.getBoundingClientRect();
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		const rect = loginBannerRect.left - bodyRect.left;
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		setBannerPositionLeft(rect);
	}, []);

	useLayoutEffect(() => {
		if (forgotPassSuccessPopupOpen) {
			dispatch(popUpOpen('resetPasswordSuccess'));
		}
	}, [dispatch, forgotPassSuccessPopupOpen]);

	const initialValues = {
		email: '',
		totp: '',
		reason: '',
	};

	const validationSchema = yup.object().shape({
		email: yup
			.string()
			.required('Please enter your email.')
			.email('Please enter a valid email address.')
			.max(60, 'Email Address is too long. Maximum 60 characters.'),
		totp: yup
			.string()
			.required('Please enter 2FA code.')
			.matches(/\d/, String('Only numbers'))
			.length(6, 'Please enter 6 character 2FA code.'),
		reason: yup
			.string()
			.required('Please explain reason for password reset.')
			.max(120, 'Reason is too long. Maximum 120 characters.'),
	});

	return (
		<>
			<Helmet>
				<body className="login-page" />
				<style type="text/css">{`
					:root {
							--login-banner-left: ${bannerPositionLeft}px;
					}
			`}</style>
			</Helmet>
			<section className="login-section">
				<div className="container">
					<div className="login">
						<div className="login-form">
							<div className="login-form__title">
								<p>Reset Password</p>
							</div>
							<Formik
								initialValues={initialValues}
								validationSchema={validationSchema}
								onSubmit={(values: IResetPasswordSubmitValue, { resetForm, setSubmitting }) => {
									onSubmit(values);
									setSubmitting(false);
									// resetForm();
								}}
								validateOnBlur
								enableReinitialize
							>
								{({ isSubmitting, isValid, dirty }) => (
									<Form className="form">
										<div className="login-form-item">
											<div className="input">
												<Field
													required
													name="email"
													type="email"
													placeholder="Email"
													component={Input}
													title="Email"
												/>
											</div>
										</div>
										<div className="login-form-item">
											<div className="input">
												<Field
													required
													name="totp"
													type="text"
													placeholder="Code"
													component={InputTotp}
													className="input-item--pr-125"
													title="Authentication Code"
													link={navListBeforeLogin.resetTwoFaPage.path}
													linkTitle="Reset 2FA Code"
												/>
											</div>
										</div>
										<div className="login-form-item">
											<div className="input">
												<p className="input__name">Reason</p>
												<div className="input-wrapper">
													<Field placeholder="Reason" name="reason" required component={Input} />
												</div>
											</div>
										</div>
										<div className="login-recaptcha">
											<ReCAPTCHA
												ref={reCaptchaRef}
												onChange={onReCaptchaChange}
												sitekey={siteKey || ''}
												hl="en"
											/>
										</div>
										<div className="login-form-item">
											<button
												type="submit"
												disabled={isSubmitting}
												className="btn btn-primary btn--full"
											>
												Confirm
											</button>
										</div>
									</Form>
								)}
							</Formik>
						</div>
					</div>

					<div className="login-banner" id="login_banner">
						<img src={loginBannerImage} alt="" />
					</div>
				</div>
			</section>

			<PopUp />
		</>
	);
};

export default ResetPasswordPage;
