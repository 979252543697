import {
	EApiCryptoTransactionDestination,
	EApiCryptoTransactionStatus,
	EApiFiatTransactionStatus,
	EApiPendingWithrawalsTransactionStatus,
	EApiPooledDepositTransactionStatus,
	EApiProfitWalletTransactionStatus,
	EApiTransactionType,
} from 'services/api/transactions/types';

export const INIT_FILTER_OPTIONS_BANK = [
	{
		id: 0,
		bank_name: 'All',
		value: undefined,
	},
];
///
export const INIT_FILTER_OPTIONS = [
	{
		id: 0,
		name: 'All',
		value: undefined,
	},
];
export const INIT_FILTER_OPTIONS_BALANCES_CURRENCY = [
	{
		id: 0,
		name: 'Сurrency',
		value: undefined,
	},
];
export const INIT_FILTER_OPTIONS_BALANCES_TOTAL = [
	{
		id: 0,
		name: 'Total EUR',
		value: undefined,
	},
];
export const INIT_DESTINATION_OPTIONS = [
	{
		id: 0,
		name: 'Destination',
		value: undefined,
	},
];

export const STATUS_CRYPTO_FILTER_OPTIONS = [
	{
		id: 1,
		name: 'Completed',
		value: EApiCryptoTransactionStatus.COMPLETED,
	},
	{
		id: 2,
		name: 'Failed',
		value: EApiCryptoTransactionStatus.FAILED,
	},
	// {
	// 	id: 3,
	// 	name: 'Canceled',
	// 	value: EApiCryptoTransactionStatus.CANCELED,
	// },
	{
		id: 4,
		name: 'Pending',
		value: EApiCryptoTransactionStatus.PENDING,
	},
];

export const DESTINATION_CRYPTO_FILTER_OPTIONS = [
	{
		id: 1,
		name: 'Destination',
		value: EApiCryptoTransactionDestination.DESTINATION,
	},
	{
		id: 2,
		name: 'Tx Hash',
		value: EApiCryptoTransactionDestination.TX_HASH,
	},
];

export const STATUS_FIAT_FILTER_OPTIONS = [
	{
		id: 1,
		name: 'Completed',
		value: EApiFiatTransactionStatus.COMPLETED,
	},
	{
		id: 2,
		name: 'Failed',
		value: EApiFiatTransactionStatus.FAILED,
	},
	{
		id: 3,
		name: 'Canceled',
		value: EApiFiatTransactionStatus.CANCELED,
	},
	{
		id: 4,
		name: 'Rejected',
		value: EApiFiatTransactionStatus.REJECTED,
	},
	{
		id: 5,
		name: 'Pending',
		value: EApiFiatTransactionStatus.PENDING,
	},
];

export const STATUS_FIAT_FILTER_OPTIONS_V2 = [
	{
		id: 1,
		name: 'Completed',
		value: EApiFiatTransactionStatus.COMPLETED,
	},
	{
		id: 2,
		name: 'Failed',
		value: EApiFiatTransactionStatus.FAILED,
	},
	{
		id: 3,
		name: 'Pending',
		value: EApiFiatTransactionStatus.PENDING,
	},
];
export const STATUS_POOLED_DEPOSIT_FILTER_OPTIONS = [
	{
		id: 1,
		name: 'Success',
		value: EApiPooledDepositTransactionStatus.COMPLETED,
	},
	{
		id: 2,
		name: 'Failed',
		value: EApiPooledDepositTransactionStatus.FAILED,
	},
	{
		id: 3,
		name: 'Pending',
		value: EApiPooledDepositTransactionStatus.ADMIN_PENDING,
	},
];
export const STATUS_PROFIT_WALLET_FILTER_OPTIONS = [
	{
		id: 1,
		name: 'Completed',
		value: EApiProfitWalletTransactionStatus.COMPLETED,
	},
	{
		id: 2,
		name: 'Failed',
		value: EApiProfitWalletTransactionStatus.FAILED,
	},
	{
		id: 3,
		name: 'Pending',
		value: EApiProfitWalletTransactionStatus.ADMIN_PENDING,
	},
];

export const STATUS_PENDING_WITHDRAWAL_FILTER_OPTIONS = [
	{
		id: 1,
		name: 'Completed',
		value: EApiPendingWithrawalsTransactionStatus.COMPLETED,
	},
	{
		id: 2,
		name: 'Failed',
		value: EApiPendingWithrawalsTransactionStatus.FAILED,
	},
	{
		id: 3,
		name: 'Pending',
		value: EApiPendingWithrawalsTransactionStatus.ADMIN_PENDING,
	},
];

export const TYPE_FILTER_OPTIONS = [
	{
		id: 1,
		name: 'Deposit',
		value: EApiTransactionType.DEPOSIT,
	},
	{
		id: 2,
		name: 'Withdrawal',
		value: EApiTransactionType.WITHDRAWAL,
	},
];
