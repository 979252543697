/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-param-reassign */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
	IApiGetTransactionParams,
	IApiPostFiatDepositParams,
	IApiPutWithdrawalLimitPayload,
	IGeneralWithdrawalLimit,
	IGetFilePayload,
	IGetPendingWithdrawalPayloadRequest,
	IPendingWithdrawalFilePayloadRequest,
	IPutCryptoNotePayloadRequest,
	IPutFiatTransactionsPayload,
	IPutGeneralWithdrawalLimitPayload,
	IPutPendingWithdrawalPayloadRequest,
	IPutTradeLimitPayload,
	ITradeLimitsResponse,
	TApiGetCryptoHistoryResponse,
	TApiGetCryptoTransactionsResponse,
	TApiGetFiatHistoryResponse,
	TApiGetFiatTransactionsResponse,
	TApiGetTradeHistoryResponse,
	TCryptoDepositsResponse,
	TGetPooledDepositsResponse,
	TPendingWithdrawalResponse,
	TProfitWalletResponse,
	TTradesResponse,
	TWithdrawalLimitResponse,
} from 'services/api/transactions/types';

import {
	IGetTradeLimitPayload,
	IGetTransactionHistoryRequestPayload,
	ITransactionsStore,
	TFiatDepositsList,
} from './types';

export const initialState: ITransactionsStore = {
	// new
	allBankAccount: [],
	allBankAccountLoading: false,
	//
	cryptoHistory: null,
	fiatHistory: null,
	tradeHistory: null,
	historyLoading: false,
	cryptoDepositsList: null,
	fiatDepositsList: null,
	depositLoading: false,
	cryptoTransactions: null,
	cryptoTransactionsLoading: false,
	cryptoTransactionsLdLoading: false,
	cryptoTransactionsDepositLoading: false,
	fiatTransactions: null,
	fiatTransactionsLoading: false,
	pooledDeposits: null,
	pooledDepositsLoading: false,
	withdrawalLimits: null,
	withdrawalLimitsLoading: false,
	withdrawalGeneralLimit: null,
	withdrawalGeneralLimitLoading: false,
	trades: null,
	tradesLoading: false,
	tradesLimits: null,
	tradesLimitsLoading: false,
	profitWallets: null,
	profitWalletsLoading: false,
	pendingWithdrawal: null,
	pendingWithdrawalLoading: false,
};

const transactions = createSlice({
	name: '@@transactions',
	initialState,
	reducers: {
		// new
		getResetTransactionsState: () => {
			return initialState;
		},
		getAllBankAccountRequest: (state) => {
			state.allBankAccountLoading = true;
		},
		getAllBankAccountSuccess: (state, { payload }: PayloadAction<any>) => {
			state.allBankAccount = payload;
			state.allBankAccountLoading = false;
		},
		getCryptoHistoryRequest: (
			state,
			action: PayloadAction<IGetTransactionHistoryRequestPayload>,
		) => {
			state.historyLoading = true;
		},
		getCryptoHistorySuccess: (state, { payload }: PayloadAction<TApiGetCryptoHistoryResponse>) => {
			state.cryptoHistory = payload;
			state.historyLoading = false;
		},
		getCryptoHistoryError: (state) => {
			state.cryptoHistory = null;
			state.historyLoading = false;
		},

		getFiatHistoryRequest: (state, action: PayloadAction<IGetTransactionHistoryRequestPayload>) => {
			state.historyLoading = true;
		},
		getFiatHistorySuccess: (state, { payload }: PayloadAction<TApiGetFiatHistoryResponse>) => {
			state.fiatHistory = payload;
			state.historyLoading = false;
		},
		getFiatHistoryError: (state) => {
			state.fiatHistory = null;
			state.historyLoading = false;
		},

		getTradeHistoryRequest: (
			state,
			action: PayloadAction<IGetTransactionHistoryRequestPayload>,
		) => {
			state.historyLoading = true;
		},
		getTradeHistorySuccess: (state, { payload }: PayloadAction<TApiGetTradeHistoryResponse>) => {
			state.tradeHistory = payload;
			state.historyLoading = false;
		},
		getTradeHistoryError: (state) => {
			state.tradeHistory = null;
			state.historyLoading = false;
		},
		getCryptoDepositsRequest: (state) => {
			state.depositLoading = true;
		},
		getCryptoDepositsSuccess: (state, { payload }: PayloadAction<TCryptoDepositsResponse>) => {
			state.cryptoDepositsList = payload;
			state.depositLoading = false;
		},
		getFiatDepositsRequest: (state) => {
			state.depositLoading = true;
		},
		getFiatDepositsSuccess: (state, { payload }: PayloadAction<TFiatDepositsList>) => {
			state.fiatDepositsList = payload;
			state.depositLoading = false;
		},

		getCryptoTransactionsRequest: (state, action: PayloadAction<IApiGetTransactionParams>) => {
			state.cryptoTransactionsLdLoading = true;
			state.cryptoTransactionsDepositLoading = true;
			state.cryptoTransactionsLoading = true;
		},
		getCryptoTransactionsSuccess: (
			state,
			{ payload }: PayloadAction<TApiGetCryptoTransactionsResponse>,
		) => {
			state.cryptoTransactions = payload;
			state.cryptoTransactionsLoading = false;
			state.cryptoTransactionsLdLoading = false;
			state.cryptoTransactionsDepositLoading = false;
		},
		putCryptoWithdrawalApproveRequest: (state, action: PayloadAction<number>) => {
			state.cryptoTransactionsLoading = true;
		},
		putCryptoWithdrawalRejectRequest: (state, action: PayloadAction<number>) => {
			state.cryptoTransactionsLoading = true;
		},
		putCryptoNoteRequest: (state, action: PayloadAction<IPutCryptoNotePayloadRequest>) => {
			state.cryptoTransactionsLoading = true;
		},
		disableCryptoTransactionsLoading: (state) => {
			state.cryptoTransactionsLoading = false;
		},

		getFiatTransactionsRequest: (state, action: PayloadAction<IApiGetTransactionParams>) => {
			state.fiatTransactionsLoading = true;
		},
		getFiatTransactionsSuccess: (
			state,
			{ payload }: PayloadAction<TApiGetFiatTransactionsResponse>,
		) => {
			state.fiatTransactions = payload;
			state.fiatTransactionsLoading = false;
		},
		putFiatTransactionsRequest: (state, action: PayloadAction<IPutFiatTransactionsPayload>) => {
			state.fiatTransactionsLoading = true;
		},
		postFiatDepositRequest: (state, { payload }: PayloadAction<IApiPostFiatDepositParams>) => {
			state.fiatTransactionsLoading = true;
		},
		disableFiatTransactionsLoading: (state) => {
			state.fiatTransactionsLoading = false;
		},
		getPooledDepositsRequest: (state, action: PayloadAction<IApiGetTransactionParams>) => {
			state.pooledDepositsLoading = true;
		},
		getPooledDepositsSuccess: (state, { payload }: PayloadAction<TGetPooledDepositsResponse>) => {
			state.pooledDeposits = payload;
		},
		getPooledDepositsFileRequest: (state, action: PayloadAction<IGetFilePayload>) => {},
		disablePooledDepositsLoading: (state) => {
			state.pooledDepositsLoading = false;
		},
		getCryptoWithdrawalLimitsRequest: (state, action: PayloadAction<IApiGetTransactionParams>) => {
			state.withdrawalLimitsLoading = true;
		},
		getFiatWithdrawalLimitsRequest: (state, action: PayloadAction<IApiGetTransactionParams>) => {
			state.withdrawalLimitsLoading = true;
		},
		getWithdrawalLimitsSuccess: (state, { payload }: PayloadAction<TWithdrawalLimitResponse>) => {
			state.withdrawalLimits = payload;
		},
		putWithdrawalLimitsRequest: (
			state,
			{ payload }: PayloadAction<IApiPutWithdrawalLimitPayload>,
		) => {
			// state.withdrawalLimitsLoading = true;
		},
		updateWithdrawalLimitsRequest: (state, { payload }: PayloadAction<any>) => {
			const newState = state;
			const updatableItemIndex = newState?.withdrawalLimits?.data.findIndex(
				(item: any) => item.id === payload.id,
			);
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			const updatableItem = newState?.withdrawalLimits?.data[updatableItemIndex];
			const updatedItem = { ...updatableItem, ...payload.data };
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			const updatedItems = [...newState.withdrawalLimits.data];
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			updatedItems[updatableItemIndex] = updatedItem;
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			newState.withdrawalLimits.data = updatedItems;
		},
		disableWithdrawalLimitsLoading: (state) => {
			state.withdrawalLimitsLoading = false;
		},

		getGeneralWithdrawalLimitRequest: (state) => {
			state.withdrawalGeneralLimitLoading = true;
		},
		getGeneralWithdrawalLimitSuccess: (
			state,
			{ payload }: PayloadAction<IGeneralWithdrawalLimit>,
		) => {
			state.withdrawalGeneralLimit = payload;
		},
		putGeneralWithdrawalLimitRequest: (
			state,
			action: PayloadAction<IPutGeneralWithdrawalLimitPayload>,
		) => {
			state.withdrawalGeneralLimitLoading = true;
		},
		disableGeneralWithdrawalLimitLoading: (state) => {
			state.withdrawalGeneralLimitLoading = false;
		},

		getTradesRequest: (state, action: PayloadAction<IApiGetTransactionParams>) => {
			state.tradesLoading = true;
		},
		getTradesSuccess: (state, { payload }: PayloadAction<TTradesResponse>) => {
			state.trades = payload;
		},
		disableTradesLoading: (state) => {
			state.tradesLoading = false;
		},

		getTradingLimitsCryptoRequest: (state, { payload }: PayloadAction<IGetTradeLimitPayload>) => {
			state.tradesLimitsLoading = true;
		},
		getTradingLimitsFiatRequest: (state, { payload }: PayloadAction<IGetTradeLimitPayload>) => {
			state.tradesLimitsLoading = true;
		},
		getTradingLimitsSuccess: (state, { payload }: PayloadAction<ITradeLimitsResponse>) => {
			state.tradesLimitsLoading = false;
			state.tradesLimits = payload;
		},
		putTradingLimitRequest: (state, { payload }: PayloadAction<IPutTradeLimitPayload>) => {
			state.tradesLimitsLoading = false;
		},
		updateTradingLimitRequest: (state, { payload }: PayloadAction<any>) => {
			const newState = state;
			const updatableItemIndex = newState?.tradesLimits?.data.findIndex(
				(item: any) => item.id === payload.id,
			);
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			const updatableItem = newState?.tradesLimits?.data[updatableItemIndex];
			const updatedItem = { ...updatableItem, ...payload.data };
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			const updatedItems = [...newState.tradesLimits.data];
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			updatedItems[updatableItemIndex] = updatedItem;
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			newState.tradesLimits.data = updatedItems;
		},
		disableTradingLimitsLoading: (state) => {
			state.tradesLimitsLoading = false;
		},
		getTradesFileRequest: (
			state,
			action?: PayloadAction<IGetFilePayload & { adminId?: number | string }>,
		) => {},
		getTradesTransfersCryptoFileRequest: (
			state,
			action?: PayloadAction<IGetFilePayload & { adminId?: number | string }>,
		) => {},
		getCryptoTransactionFileRequest: (state, action?: PayloadAction<IGetFilePayload>) => {},
		getFiatTransactionFileRequest: (state, action?: PayloadAction<IGetFilePayload>) => {},

		getProfitWalletsRequest: (state, action: PayloadAction<IApiGetTransactionParams>) => {
			state.profitWalletsLoading = true;
		},
		getProfitWalletsSuccess: (state, { payload }: PayloadAction<TProfitWalletResponse>) => {
			state.profitWallets = payload;
		},
		disableProfitWalletsLoading: (state) => {
			state.profitWalletsLoading = false;
		},
		getProfitWalletsFileRequest: (state, action?: PayloadAction<IGetFilePayload>) => {},

		getPendingWithdrawalRequest: (
			state,
			action: PayloadAction<IGetPendingWithdrawalPayloadRequest>,
		) => {
			state.pendingWithdrawalLoading = true;
		},
		getPendingWithdrawalSuccess: (
			state,
			{ payload }: PayloadAction<TPendingWithdrawalResponse>,
		) => {
			state.pendingWithdrawal = payload;
		},
		disablePendingWithdrawalLoading: (state) => {
			state.pendingWithdrawalLoading = false;
		},
		getPendingWithdrawalFileRequest: (
			state,
			action?: PayloadAction<IPendingWithdrawalFilePayloadRequest>,
		) => {},
		putPendingWithdrawalApproveRequest: (
			state,
			action: PayloadAction<IPutPendingWithdrawalPayloadRequest>,
		) => {
			state.pendingWithdrawalLoading = true;
		},
		putPendingWithdrawalRejectRequest: (
			state,
			action: PayloadAction<IPutPendingWithdrawalPayloadRequest>,
		) => {
			state.pendingWithdrawalLoading = true;
		},
		putPendingWithdrawalNoteRequest: (
			state,
			action: PayloadAction<IPutCryptoNotePayloadRequest>,
		) => {
			state.pendingWithdrawalLoading = true;
		},
	},
});

export default transactions.reducer;
export const {
	getResetTransactionsState,
	getAllBankAccountRequest,
	getAllBankAccountSuccess,
	getCryptoHistoryRequest,
	getCryptoHistorySuccess,
	getCryptoHistoryError,
	getFiatHistorySuccess,
	getFiatHistoryRequest,
	getFiatHistoryError,
	getTradeHistoryRequest,
	getTradeHistorySuccess,
	getTradeHistoryError,
	getCryptoDepositsRequest,
	getCryptoDepositsSuccess,
	getFiatDepositsRequest,
	getFiatDepositsSuccess,
	getCryptoTransactionsRequest,
	getCryptoTransactionsSuccess,
	disableCryptoTransactionsLoading,
	getFiatTransactionsRequest,
	postFiatDepositRequest,
	getFiatTransactionsSuccess,
	putFiatTransactionsRequest,
	disableFiatTransactionsLoading,
	putCryptoWithdrawalApproveRequest,
	putCryptoWithdrawalRejectRequest,
	putCryptoNoteRequest,
	getPooledDepositsRequest,
	getPooledDepositsSuccess,
	disablePooledDepositsLoading,
	getCryptoWithdrawalLimitsRequest,
	getFiatWithdrawalLimitsRequest,
	getWithdrawalLimitsSuccess,
	putWithdrawalLimitsRequest,
	updateWithdrawalLimitsRequest,
	disableWithdrawalLimitsLoading,
	getGeneralWithdrawalLimitRequest,
	getGeneralWithdrawalLimitSuccess,
	putGeneralWithdrawalLimitRequest,
	disableGeneralWithdrawalLimitLoading,
	getTradesRequest,
	getTradesSuccess,
	disableTradesLoading,
	getTradingLimitsCryptoRequest,
	getTradingLimitsFiatRequest,
	getTradingLimitsSuccess,
	putTradingLimitRequest,
	updateTradingLimitRequest,
	disableTradingLimitsLoading,
	getTradesFileRequest,
	getTradesTransfersCryptoFileRequest,
	getCryptoTransactionFileRequest,
	getFiatTransactionFileRequest,
	getPooledDepositsFileRequest,
	getProfitWalletsRequest,
	getProfitWalletsSuccess,
	disableProfitWalletsLoading,
	getProfitWalletsFileRequest,
	getPendingWithdrawalRequest,
	getPendingWithdrawalSuccess,
	disablePendingWithdrawalLoading,
	getPendingWithdrawalFileRequest,
	putPendingWithdrawalApproveRequest,
	putPendingWithdrawalRejectRequest,
	putPendingWithdrawalNoteRequest,
} = transactions.actions;
